import request from '@/utils/request'
import setting from '@/config/setting'

// api地址
const api = {
  login: '/app/portal/login',
  smsCaptcha: '/app/portal/sms/captcha',
  loginByPhoneSMS: '/app/portal/login/mpweixin',
  getLoginQRCode: '/app/portal/login/qrcode',
  upload: setting.uploadUrl
}

/**
 * 用户登录
 * @data data 参数
 * @returns {Promise<Array>}
 */
export function login(data) {
  return new Promise((resolve, reject) => {
    request.post(api.login, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 发送短信验证码
 * @data data 参数
 * @returns {Promise<Array>}
 */
export function smsCaptcha(data) {
  return new Promise((resolve, reject) => {
    request.post(api.smsCaptcha, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 手机号验证码登录
 * @data data 参数
 * @returns {Promise<Array>}
 */
export function loginByPhoneSMS(data) {
  return new Promise((resolve, reject) => {
    request.post(api.loginByPhoneSMS, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 手机号验证码登录
 * @data data 参数
 * @returns {Promise<Array>}
 */
export function getLoginQRCode() {
  return new Promise((resolve, reject) => {
    request.get(api.getLoginQRCode).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 *
 * @param {*} data
 * @returns
 */
export function upload(data) {
  return new Promise((resolve, reject) => {
    request.post(api.upload, data, {
      'content-type': 'multipart/form-data'
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
